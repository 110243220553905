// #docregion token
import { InjectionToken } from '@angular/core';
import { IAppConfig } from './IApp.config';
export { IAppConfig } from './IApp.config';
import { environment } from '../../../environments/environment';
import { APP_ROLES_CONSTANT } from './app.constant';
export const APP_CONFIG = new InjectionToken<IAppConfig>('app.config');
// #enddocregion token
// #docregion config
export const APP_DI_CONFIG: IAppConfig = {
  site_name: environment.SITE_NAME,
  copyright_text: environment.COPYRIGHT_TEXT,
  version: environment.version,
  isVersionCheck: environment.isVersionCheck,
  build_version: environment.build_version,
  appLogo: 'assets/images/logo.svg',
  DEFAULT_PAGINATION_LIMIT: environment.DEFAULT_PAGINATION_LIMIT || 10,
  SE:'MTIzNDU2MDAwMDAwMDAwMA==',
  VK:'MDAwMDAwMDAwMDAwMDAwMA==',
  Is_E:'R3rcMGRoRsCuiU5TkzxTrUvPzihGQBr5h96aApNMALcRKxmjZ2Sg1MsH9gZva7zKA8n5p9qN0kSj4488LYW4X2FZDkCx4f8wbYGn8G7Ps69Cyd5vhQgtdL2wqBO4KW53tmXKlEb0Pjh/MY2gRcxB87KuTBWpxIeAo7SkOMMRAcE='
};
